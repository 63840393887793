<template>
  <v-container fluid>
    <v-row dense>
      <section-title>Листокларни юклаш</section-title>
    </v-row>

    <v-divider class="light-blue lighten-8 mt-2 mb-2 elevation-10" />

    <v-row dense>
      <v-col cols="12">
        <v-file-input v-model="file" label="Yuklanadigan fayl" accept=".xlsx" />

        <v-date-picker type="month" v-model="date" class="elevation-1" />

        <v-btn
          block
          class="mt-5"
          color="primary"
          :disabled="!file || !date"
          @click="upload"
        >
          <v-icon>mdi-cloud-upload</v-icon>
          <span class="ml-3">Yuklash</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../../axios";
import SectionTitle from "../../components/SectionTitle.vue";

export default {
  name: "List",
  components: { SectionTitle },
  data: () => ({
    file: null,
    date: null,
  }),
  methods: {
    async upload() {
      try {
        this.$overlay(true);

        const formData = new FormData();
        formData.append("date", this.date + "-01");
        formData.append("file", this.file);

        const { data } = await api.post("/lists", formData);

        this.$toast.success(data.message || "List yuklandi!");
        this.file = null;
        this.date = null;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error.message);
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
